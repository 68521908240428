import React from 'react';
import {Container,Row,Col,Button} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown'

import globals from "../../globals.json";
import { useQuery } from 'react-query';
import {fetch_app_settings} from '../../Data.js';

import NewsPreview from './NewsPreview.js';

//props is a query containing news search results
export default function NewsNavigation(props)
{
    let content = '';
    if(props.newsQuery.isLoading)
    {
        content = <div>Loading...</div>;
    }
    else if(props.newsQuery.isError)
    {
        content = <div>Error...</div>;
    }
    else
    {
        let searchResults = props.newsQuery.data;
        let prevButton = '';
        let nextButton = '';

        if(searchResults.has_more)
        {
            nextButton = 
                <Button href={`/news?page=${searchResults.page}&num=${searchResults.per_page}`}>Newer</Button>
        }
        if(searchResults.has_previous)
        {
            prevButton = 
                <Button href={`/news?page=${searchResults.page}&num=${searchResults.per_page}`}>Older</Button>
        }

        content = 
            <Row>
                <Col xs={2}>{prevButton}</Col>
                <Col xs={8}></Col>
                <Col xs={2}>{nextButton}</Col>
            </Row>

    }
    return content;    
}
