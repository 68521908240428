import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown'

import globals from "../../globals.json";
import { useQuery } from 'react-query';
import {fetch_app_settings} from '../../Data.js';

import NewsListItem from './NewsListItem';

//props is a query object containing a list of posts.
export default function NewsList(props)
{
    let content = '';
    if(props.newsQuery.isLoading)
    {
        content = <div>Loading...</div>;
    }
    else if(props.newsQuery.isError)
    {
        content = <div>Error...</div>;
    }
    else
    {
        let posts = props.newsQuery.data.posts;

        let newsPreviewList = posts.map((lineItem) =>
            <NewsListItem key={lineItem.id} post={lineItem}/>
        )

        content = <div className="newsListItem-row">{newsPreviewList}</div>;
    }
    return content;    
}
