import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import globals from "../../globals.json";
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import parse from "html-react-parser";
import ImageHeader from '../shared/ImageHeader';
import LatestNewsWidget from './LatestNewsWidget';

//props is a query object containing a list of posts.
export default function NewsDetailPage(props)
{
    const {slug} = useParams();
    let content = '';
    const newsQuery = useQuery(['news',{slug: slug}],()=>
        fetch(`${globals.api_base_url}/news/post/${slug}`).then(res=> res.json())
    );


    if(newsQuery.isLoading)
    {
        content = <div>Loading...</div>;
    }
    else if(newsQuery.isError)
    {
        content = <div>Error...</div>;
    }
    else
    {
        let post = newsQuery.data;
        let date = format(new Date(post.date),'MMMM d, yyyy');

        content =
        <>
            <ImageHeader title={post.title} imageUrl={post.featured_image} dateline={date} />
            <div className="news-body">{parse(post.content)}</div>
            <LatestNewsWidget title="More News"/>            
        </>

    }
    return content;    

}
