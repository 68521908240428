import { useQuery } from 'react-query';
import NewsList from './NewsList';
import globals from "../../globals.json";
export default function LatestNewsWidget(props)
{
	const recentNewsQuery = useQuery(['news','recent'],()=>
    fetch(`${globals.api_base_url}/news?num=4`).then(res=> res.json()));
    let title = props.title;
    return(
        <>            
            <div className="latestNewsWidget">
                <h2 className="latestNewsWidget-header">{title}</h2>
                <NewsList newsQuery={recentNewsQuery} />
                <div className="latestNewsWidget-readMore"><a className="link-btn-tertiary" href="/news/">Read More News</a></div>
            </div> 
        </>);

}