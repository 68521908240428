//import React, { useState, useEffect } from 'react';
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import globals from "../../globals.json";
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import EventDetailLocationPanel from './EventDetailLocationPanel';

//import ReactMarkdown from "react-markdown";

export default function EventDetailMainPanel(props) {
    let event = props.event; 

    let html = parse(event.description);
    let description = 
        <div className="section">{html}</div>;
    
    html = parse(event.schedule);
    let schedule = 
        <div className="section">
            <a id="schedule" />
            <h2>Event Schedule</h2>
            <div>{html}</div>    
        </div>;


	return (
        <>
            {description}
            {schedule}
            <EventDetailLocationPanel location={event.location}/>
        </>
    );
}