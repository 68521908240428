import './FeatureBox.css';

export default function FeatureBox(props)
{
    let footerDiv = '';
    if(props.footerContent !== undefined)
    {
        footerDiv = <div className='featureBox-footer'>{props.footerContent}</div>
    }
    return(
        <div className="featureBox">
            <div className="featureBox-label">
                {props.labelContent}
            </div>
            <div className="featureBox-main">
                <div className="featureBox-header">
                    {props.headerContent}
                </div>
                <div className="featureBox-body">
                    {props.bodyContent}
                </div>
            </div>
            {footerDiv}
            
        </div>
    );
}