//import React, { useState, useEffect } from 'react';
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import globals from "../../globals.json";
import { useQuery } from 'react-query';
import { format, parseISO } from 'date-fns';

import ErrorPanel from '../shared/ErrorPanel';
import LoadingWidget from '../shared/LoadingWidget';

import EventDetailMainPanel from './EventDetailMainPanel';
import './Events.css';
import ImageHeader from "../shared/ImageHeader";
import EventLocationWidget from "./EventLocationWidget";
import WideTile from '../shared/WideTile';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


//import ReactMarkdown from "react-markdown";

export default function EventDetailPage(props) {
	const { eventId } = useParams();
	const eventQuery = useQuery(['events',eventId], () =>
	fetch(`${globals.api_base_url}/events/${eventId}`).then(res =>
	  res.json()
	));

	if(eventQuery.isError)
	{
		return <ErrorPanel />
	}
	else if(eventQuery.isLoading)
	{
		return <LoadingWidget />
	}

	let event = eventQuery.data; 
	let title = event.title;
	let isFuture = new Date(event.start_date).getTime() > new Date().getTime();
	
	let date; 
    if(event.end_date !== null)
    {
		date = `${format(new Date(event.start_date),'EEE, MMMM d')}—${format(new Date(event.end_date),'EEE, MMM d, yyyy')}`;
    }
	else
	{
		date = format(new Date(event.start_date),'EEEE, MMMM d, yyyy');
	}
	let datePres = <div className="eventHeader-date-wrapper">
		<div className="eventListItem-location-pin"><FontAwesomeIcon icon="fa-calendar-days" /></div>
		<div className="eventHeader-date-text">{date}</div>
	</div>;

	let extendedLocation = <div>Location TBA</div>;
	if(event.location !== null && event.location !== undefined)
	{
		extendedLocation = <EventLocationWidget location={event.location} />
	}

	let featured_image = '';
    if(event.featured_image !== undefined)
    {
        featured_image =     
            <img style={{width: "100%", objectFit: "contain"}} src={event.featured_image} />
    }

	let footerElements = [];
	let buttons = [];
    if(event.registration_url !== null && isFuture)
    {
        buttons.push(<a className="link-btn-tertiary" key="RegisterLink" href={event.registration_url}>Register</a>);
		footerElements.push(buttons);
	}
    else if(event.results.length > 0)
    {

		let resultsElements = [];
		let currentZ = 10;
    	
        let resultsLabel = <div className="button-series-label" key="resultsLabel" style={{zIndex: currentZ}}>Results</div>;
        resultsElements.push(resultsLabel);
        event.results.forEach(element => {
            currentZ--;
            resultsElements.push(<a className="link-btn-hollow button-series" key={element.label} href={element.url} target="_new" style={{zIndex:currentZ}}>{element.label}</a>);
        });
		let resultsDiv = <div key="resultsDiv" className="isolated">{resultsElements}</div>;
		footerElements.push(resultsDiv);
	}
	let reminderPanelProps = 
	{
		title: "Rules Reminders",
		body: "Before you register, brush up on our helmet rules, sound limits, and more.",
		imageUrl:globals.media_url + "2023/01/36str_sm.jpg",
		readMoreUrl:"/about/rules/",
		readMoreLabel:"Read the Rules"
	};

	let novicePanelProps = 
	{
		title: "First Event?",
		body: "If this is going to be your first event with the club, check out our guide to getting started in autocross.",
		imageUrl:globals.media_url + "2023/01/novice_sts_sm.jpg",
		readMoreUrl:"/getting-started/",
		readMoreLabel:"Novice Guide",
		imagePosition:"right"
	}
	let content =
		<> 
			<ImageHeader title={title} subtitle={datePres} imageUrl={event.featured_image} body={extendedLocation} footer={footerElements} />			
			<EventDetailMainPanel event={event} />
			<WideTile {...reminderPanelProps} />
			<WideTile {...novicePanelProps} />
		</>
	return (
		content
	);
}