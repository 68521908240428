import LoadingWidget from './LoadingWidget';
import ErrorPanel from './ErrorPanel';

import './ImageHeader.css';

export default function ImageHeader(props)
{
    let title = props.title;
    let subtitle = props.subtitle !== null && props.subtitle !== undefined?props.subtitle:'';
    let image = props.imageUrl !== null && props.imageUrl !== undefined? props.imageUrl : '';
    let dateline = props.dateline !== null && props.dateline !== undefined? props.dateline:'';
    let footer = props.footer !== null && props.footer !== undefined? props.footer : '';
    let body = props.body !== undefined?props.body : '';
    let bodyDiv = '';
    console.log('body: ' + body);
    if(body !== '')
    {
        bodyDiv = <div className="imageHeader-body">{body}</div>;
    }
    let content = 
    <div className="imageHeader-wrapper">
                <div className="imageHeader-image">
            <img src={image}></img>
        </div>
        <div className="imageHeader-text">
            <div className="imageHeader-text-inner">
                <h1 className="imageHeader-title">{title}</h1>
                <div className="imageHeader-subtitle">{subtitle}</div>
                <div className="imageHeader-dateline">{dateline}</div>
                {bodyDiv}
                <div className="imageHeader-footer">{footer}</div>            
            </div>
        </div>

    </div>

    return content;
}