import React, {useState, useRef} from 'react';

import globals from "../../globals.json";
import {fetch_app_settings} from '../../Data.js';

import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
import { useQuery } from 'react-query';
import axios from 'axios';
import LoadingWidget from '../shared/LoadingWidget';
import './Contact.css';

function ContactPage(props)
{

    const settings = useQuery(['settings'],
        fetch_app_settings,
        globals.max_cache_config
    );
    
    const[isSubmitting,setSubmitting] = useState(false);
    const[isSubmitted,setSubmitted] = useState(false);
    const[validationFailed,setValidationFailed] = useState(false);
    const[recaptchaComplete,setRecaptchaComplete] = useState(false);

    console.log('recaptchacomplete: ' + recaptchaComplete);
    const form = useRef(null);

    const recaptchaCompleted = (e) =>{
        console.log("cmpleted recaptcha");
        setRecaptchaComplete(true);
        setValidationFailed(false);
    }

    const submitForm = (e) =>{
        console.log('recaptchacomplete in submitForm: ' + recaptchaComplete);
        e.preventDefault();
        
        if(!recaptchaComplete)
        {
            console.log("recaptcha incomplete");
            setValidationFailed(true);
            return;
        }
        
        setSubmitting(true);        
        var data = new FormData(form.current);
        console.log(data);
        axios({
            method:"post",
            url: `${globals.api_base_url}/contact/`,
            data: data
        })
        .then(function(response)
        {
            setValidationFailed(false);
            setSubmitting(false);
            setSubmitted(true);            
        })
        .catch(function (error) {
            if(error.response){
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setValidationFailed(true);
                setSubmitting(false);
                setSubmitted(false);
            }
        });
          
        let val = $("#email").val();
        console.log("email: ",val);
    };
    let formContent;
    let contactCopy;
    let warningMessage = validationFailed ? <div className="validation-error">Please confirm you are not a self-driving car. We haven't added a class for that yet.</div> : "";
    if(settings.isLoading)
    {
        contactCopy = <LoadingWidget/>;
        formContent = <LoadingWidget/>;
    }
    else{
        contactCopy = <p className="section">If you're looking to make connections with local autocross enthusiasts or club officers, 
        our <a href={settings.data.scchq_discord_url} target="_blank">Discord server</a> and <a href={settings.data.scchq_facebook_url} target="_blank">Facebook group</a> are both great places to meet other club members. 
        We also meet face-to-face every month to grab dinner and talk about racing.</p>
    }
    //settingss are loaded, form is fresh
    if(!settings.isLoading && !isSubmitted)
    {        
        formContent =  
        <>            
            <p>Use this form to get in touch directly with club officers. We'll reply as soon as we can.</p>
            
            <form className="contact-form" ref={form} onSubmit={submitForm}>
                <div className="form-group">
                    <label htmlFor="email">Your Email Address</label>
                    <br/><input required type="email" name="email" id="email" placeholder="you@auto-x.com" />
                    <div className="text-muted">
                        We'll never share your email address.
                    </div> 
                </div>
                <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <br/><input type="text" name="subject" id="subject" required/>                    
                </div>
                <div className="form-group">
                    <label htmlFor="body">Message</label>
                    <br/><textarea className="contact-form-body" id="body" rows={5} name="body" required></textarea>
                </div>

                <ReCAPTCHA className="form-group"
                    sitekey={settings.data.scchq_recaptcha_v2_site_key}
                    size="normal"
                    onChange={recaptchaCompleted}
                />
                {warningMessage}
                <input type="submit" className="link-btn-tertiary" disabled={isSubmitting} value={isSubmitting? 'Sending...' : 'Send Message'} />  
            </form>
        </>
    }

    //settings are loaded, form has been submitted
    else if(!settings.isLoading && isSubmitted)
    {
        formContent = 
            <div>
                <b>Thanks for your email!</b> 
                <div>You should expect a reply within a day or two. In the meantime, join us on <a href="" target="_blank">Discord</a>! </div>
            </div>
    }
    return (
        <div>
            <h1 className="section">Get in Touch</h1>
            {contactCopy}
            <h2>Contact Us</h2>
            <div>
                {formContent}
            </div>
        </div>
	);
}
export default ContactPage;