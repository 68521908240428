
import './WideTile.css';
export default function WideTile(props)
{
    let imageUrl = props.imageUrl;
    let imagePosition = props.imagePosition;

    let wrapperClass = imagePosition=="right"?"wideTile alt":"wideTile";
    let myPhotoStyle = {
        backgroundImage: 'url(' + imageUrl + ')',
    };

    let copyDiv = 
    <div className="description">
        <h2>{props.title}</h2>
        <p>{props.body}</p>
        <p className="read-more">
            <a href={props.readMoreUrl} className="link-btn-tertiary">{props.readMoreLabel}</a>
        </p>
    </div>;

    return(
        <div className={wrapperClass}>
            <div className="meta">
                <div className="photo" style={myPhotoStyle}></div>
            </div>
            <div className="body">
                <h2>{props.title}</h2>
                <p>{props.body}</p>
                <div className="wideTile-filler"> </div>
                <p className="read-more">
                    <a className="link-btn-tertiary" href={props.readMoreUrl}>{props.readMoreLabel}</a>
                </p>
            </div>
        </div>
    )
}


/* just for reference
function NewsPreview(props) 
{
    let post = props.post;
    let date = format(new Date(post.date),'EEE, MMM d');
    let imageCol = '';
    let postUrl = `/news/${post.slug}`;
    if(post.featured_image !== undefined)
    {
        let myStyle = {
            backgroundImage: 'url(' + post.featured_image + ')',
        };
        imageCol =
            <div className="newsListItem-bgImage" style={myStyle} /> 
    }
    let jumpLink = '';
    if(post.has_more_content)
    {
        jumpLink = <Link to={postUrl}>Read more &gt;&gt;</Link>
    }
    let content = 
        <div className="newsListItem">
            {imageCol}
            <div className="newsListItem-text">
                <h2><a href={postUrl}>{post.title}</a></h2>
                <div className="newsListItem-dateline">{date}</div>
                <div>{post.content}</div>
                <div className="newsListItem-jumplink">{jumpLink}</div>
            </div>
            
        </div>;
    return content;
}*/