import globals from "../../globals.json";
import { useQuery } from 'react-query';
import PageContent from "../shared/PageContent";
import LoadingWidget from "../shared/LoadingWidget";
import ErrorPanel from "../shared/ErrorPanel";
import parse from "html-react-parser";

export default function MembershipPage(props)
{    
    const content = useQuery(['pages','membership'],() =>
		fetch(`${globals.api_base_url}/pages/membership`).then(res=> res.json())
	);
    return(
        <>
        <h1>Club Membership</h1>        
        <div className="section">
            {<PageContent content={content} />}
        </div>
        </>
    );
}