import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from "react-router-dom";
import globals from "../globals.json";
import { useQuery } from 'react-query';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { format,parseISO } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OfficerCard from './OfficerCard';
import { faVestPatches } from '@fortawesome/free-solid-svg-icons';

//props.officers is a list of officers
function OfficersList(props)
{
    const officers = props.officers;

    let content = null;

    if(officers.isLoading)
    {
        content = <span>Loading...</span>;
    }
    else if(officers.isError)
    {
        content = <span>Error...</span>;
    }
    else if(officers.data.length===0)
    {        
        content = ''
    }
    else
    {
        let officerCards = 
                officers.data.map((lineItem) => 
            <Col sm={3}><OfficerCard key={lineItem.id} officer={lineItem} /></Col>);
            content = (<div>
                {officerCards}
            </div>);
    }    

    if(content.length === 0)
    {
        return (<div></div>);
    }
    else
    {
        return ( 
            <div>
        <Row>
            <Col><h2>{props.title}</h2></Col>
        </Row>
        <Row>
            {content}
        </Row>
        </div>
        );		
    }
}
export default OfficersList;