import globals from "../../globals.json";
import { useQuery } from 'react-query';
import PageContent from "../shared/PageContent";
import parse from "html-react-parser";

export default function NoviceGuidePage(props)
{

	const content = useQuery(['pages','novice'],() =>
		fetch(`${globals.api_base_url}/pages/novice`).then(res=> res.json())
	);

	let main = 
		<>
            <h1>Before Your First Event</h1>
            
			<div className="section">
				{<PageContent content={content} />}
			</div>			
		</>;
	return(			
		main
	);
}