import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapLink from '../shared/MapLink';
export default function EventLocationWidget(props)
{
    return(
        <div className="eventLocationWidget">
            <div className="eventListItem-location-pin">
            <MapLink address={props.location.address} className="stealthy-link"><FontAwesomeIcon icon="location-dot" /></MapLink>
            </div>
            <div className="eventListItem-location-text">
                <div className="eventListItem-location-name"><MapLink address={props.location.address} className="stealthy-link">{props.location.name}</MapLink></div>
                <div className="eventListItem-location-city"><MapLink address={props.location.address} className="stealthy-link">{props.location.city_state}</MapLink></div>
            </div>
        </div>
    );
}