import Container from 'react-bootstrap/Container';
import parse from "html-react-parser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './LoadingWidget.css';
//import globals from "../globals.json";


//props should be an object data / content, not a useQuery response
export default function LoadingWidget(props) {
    return (
        <div className="loading-widget"><FontAwesomeIcon icon="fa-spinner" spin /></div>
	);
}