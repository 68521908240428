import { useParams } from "react-router-dom";
import globals from "../../globals.json";
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventLocationWidget from "./EventLocationWidget";

function EventListItem(props)
{

    let date;
    let event = props.event; 
    let link = `/event/${props.event.id}/${props.event.slug}`;
    if(event.end_date !== null)
    {
		date = `${format(new Date(event.start_date),'EEE, MMMM d')}—${format(new Date(event.end_date),'EEE, MMM d')}`;
    }
	else
	{
		date = format(new Date(event.start_date),'EEEE, MMMM d');
	}
    let datePres = <a href={link} className="stealthy-link">{date}</a>;

    let location = 'Location TBA';
    if(props.event.location !== null)
    {
        let key = `loc-${props.event.id}`;
        location = 
            <EventLocationWidget key={`loc-${props.event.id}`} location={props.event.location} />;
    }
    

    let bodyContent = location;
    let labelContent = props.event.title;
    let headerContent = datePres; 
    let footerElements = [];
    let detailsElements = [];
    if(props.event.registration_url !== null && new Date(props.event.start_date).getTime() > new Date().getTime())
    {
        detailsElements.push(<a className="link-btn-tertiary" key="RegisterLink" href={props.event.registration_url}>Register</a>);
    }
    detailsElements.push(<a className="link-btn-hollow" key="DetailsLink" href={link}>Details</a>);
    footerElements.push(<div>{detailsElements}</div>);

    let resultsElements = [];
    let currentZ = 10;
    if(props.event.results.length > 0)
    {
        let resultsLabel = <div className="button-series-label" key="resultsLabel" style={{zIndex: currentZ}}>Results</div>;
        resultsElements.push(resultsLabel);
        props.event.results.forEach(element => {
            currentZ--;
            resultsElements.push(<a className="link-btn-hollow button-series" key={element.label} href={element.url} target="_new" style={{zIndex:currentZ}}>{element.label}</a>);
        });
    }
    let resultsDiv = <div key="resultsDiv" className="isolated">{resultsElements}</div>;
    footerElements.push(resultsDiv);

    let content = <div className="eventListItem">
            <div className="eventListItem-label">
                {labelContent}
            </div>
            <div className="eventListItem-main">
                <div className="eventListItem-header">
                    {headerContent}
                </div>
                <div className="eventListItem-body">
                    {bodyContent}
                </div>
            </div>
            <div className="eventListItem-footer">{footerElements}</div>            
        </div>;
    return(
        content    
    );
}
export default EventListItem;