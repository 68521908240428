import React from 'react';
import {Container,Row,Col,Button} from 'react-bootstrap';
import { Link } from "react-router-dom";

import globals from "../../globals.json";
import { useQuery } from 'react-query';
import {fetch_app_settings} from '../../Data.js';
import NewsList from './NewsList.js';
import NewsNavigation from './NewsNavigation.js';

export default function NewsPage(props)
{
    const queryParams = new URLSearchParams(window.location.search);
    let pageNum = queryParams.get("page");
    if(pageNum === null)
    {
        pageNum = 1;
    }
    let perPage = 10;

	const newsQuery = useQuery(['news',{page: pageNum, perPage: perPage}],()=>
		fetch(`${globals.api_base_url}/news?num=${perPage}&page=${pageNum}`).then(res=> res.json())
	);
	return(		
        <>
            <h1 className="section">News</h1>
            <NewsList newsQuery={newsQuery} />
            <NewsNavigation newsQuery={newsQuery} />
        </>	
	);
}
