import globals from "../../globals.json";
import LoadingWidget from "../shared/LoadingWidget";
import ErrorPanel from "../shared/ErrorPanel";
import parse from "html-react-parser";
import WideTile from "../shared/WideTile";


export default function GettingStartedPage(props)
{
    let panel1Body = "Getting into autocross doesn't have to be intimidating. Here's everything you need to know before you attend your first event. (Spoiler alert: You're going to have to clean out your car.)";
    let panel1Image = globals.media_url + "2023/01/novice_sts_sm.jpg";
    let panel1MoreLink = "/novice-guide/";
    let panel1 = 
        <WideTile body={panel1Body} title="Before Your First Event" imageUrl={panel1Image} imagePosition="left" readMoreUrl={panel1MoreLink} readMoreLabel="Read More"/>

    let panel2Title = "Car Classing Guide"
    let panel2Body = "Not sure how to class your car for registration? Here's a quick overview.";
    let panel2MoreLink = "/classing/";
    let panel2MoreLabel = "Read More";
    let panel2Image = globals.media_url + "2023/01/smf22_sm.jpg";
    let panel2 = <WideTile body={panel2Body} title={panel2Title} imageUrl={panel2Image} imagePosition="right" readMoreUrl={panel2MoreLink} readMoreLabel={panel2MoreLabel}/>

    return(
        <>
            <h1 className="section">Getting Started</h1>
            {panel1}
            {panel2}
        </>
    )
}