
import Card from './shared/FeatureBox.js';

function OfficerCard(props)
{
    return(
        <Card style={{ width: '18em' }}>
            <Card.Header>
                {props.officer.acf.position}
            </Card.Header>
            <Card.Img variant="top" src={props.officer.featured_image} width="50" />
            <Card.Body>
                <Card.Title>{props.officer.post_title}</Card.Title>
                
            </Card.Body>
        </Card>
    );
}
export default OfficerCard;