import parse from "html-react-parser";
import LoadingWidget from './LoadingWidget';
import ErrorPanel from './ErrorPanel';

import './PageContent.css';

//props should be an object as returned by React Query / event
export default function PageContent(props) {
    let content = '';
    if(props.content.isLoading)
    {
        content = <LoadingWidget/>;
    }
    else if(props.content.isError)
    {
        content = <ErrorPanel />;
    }
    else
    {
        content = <div className="page-content">{parse(props.content.data.content)}</div>;
    }
    return (
        content
	);
}
