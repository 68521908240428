import globals from "../../globals.json";
import { useQuery } from 'react-query';
import Select from 'react-select';

//for styling information: https://react-select.com/styles#the-classnameprefix-prop
/*
<div class="react-select-container">
  <div class="react-select__control">
    <div class="react-select__value-container">...</div>
    <div class="react-select__indicators">...</div>
  </div>
  <div class="react-select__menu">
    <div class="react-select__menu-list">
      <div class="react-select__option">...</div>
    </div>
  </div>
</div>
*/

function SeasonSelect(props)
{
    const onChange = (option) =>
    {
        window.location = `/season/${option.value}`;
    }
    const eventYears = useQuery(['eventYears'],() => 
        fetch(`${globals.api_base_url}/events/seasons/`).then(res=>
            {
                return res.json();
            }
            ),
        {
            staleTime : 14400000 //currently stale after 4 hours.
        }
    );
    const options = [];

    if(eventYears.isLoading)
    {
        return <span>Loading...</span>
    }
    else if(eventYears.isError)
    {
        return <span>Error: {eventYears.error.message}</span>
    }
    else{
        eventYears.data.forEach(element => {
            let option = {value:element, label:element};
            options.push(option);
        });
    
        return (
            <Select options={options} placeholder="Jump to Year" onChange={onChange} className="season-select" classNamePrefix="season-select" />
        );
    }
}
export default SeasonSelect; 