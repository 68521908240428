import React from 'react';

//import { fab } from '@fortawesome/free-brands-svg-icons'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAnglesLeft, faAnglesRight, faBars, faSpinner, faDumpsterFire, faLocationDot, faCalendarDays} from '@fortawesome/free-solid-svg-icons'
//import {faFacebook, faDiscord} from '@fortawesome/free-brands-svg-icons';

import './styles/reset.css';
import './App.css';

import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
//Globals
import globals from "./globals.json";
import {fetch_app_settings} from './Data.js';

//our Components

import Footer from './components/footer/Footer';
import NavBar from './components/nav-bar/NavBar';

import HomePage from './components/home/HomePage.js';
import AboutPage from './components/about/AboutPage';
import MembershipPage from './components/about/MembershipPage';
import RulesPage from './components/about/RulesPage.js'

import EventDetailPage from './components/events/EventDetailPage.js';
import SeasonList from './components/events/SeasonList.js';


import ClassingPage from './components/getting-started/ClassingPage';
import ContactPage from './components/contact/ContactPage.js';
import GettingStartedPage from './components/getting-started/GettingStartedPage';
import NewsPage from './components/news/NewsPage.js';
import NewsDetailPage from './components/news/NewsDetailPage.js';
import NoviceGuidePage from './components/getting-started/NoviceGuidePage';
import OfficersPage from './components/OfficersPage.js';



function App(){
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  library.add(faAnglesLeft, faAnglesRight, faBars,faSpinner,faDumpsterFire,faLocationDot,faCalendarDays);

  let mainPanelRouter = 
    <Router>
        <Route exact path="/">
          <HomePage />
        </Route>   

        <Route exact path="/about/">
          <AboutPage />
        </Route>
        <Route exact path="/about/membership/">
          <MembershipPage />
        </Route>
        <Route exact path="/about/membership/application" component={()=>
          {
            window.location.replace(globals.media_url + "2022/06/TriadMembershipapplication.pdf");
          }
        }>
        </Route>
        <Route exact path="/about/constitution" component={()=>
        {
          window.location.replace(globals.media_url + "2023/01/TSCC-Constitution.pdf");
        }}>
        </Route>
        <Route exact path="/about/rules/">
          <RulesPage />
        </Route>


        <Route path="/classing/">
          <ClassingPage />
        </Route>

        <Route path="/contact/">
          <ContactPage />
        </Route>

        <Route exact path="/events/">
          <SeasonList />
        </Route>
        <Route path="/season/:year">
            <SeasonList />
          
        </Route>     
        <Route path="/event/:eventId/:slug?"> 
          <EventDetailPage />
        </Route>

        <Route path="/getting-started/">
          <GettingStartedPage />
        </Route>

        

        <Route exact path="/news/">
          <NewsPage />
        </Route>
        <Route path="/news/:slug">
          <NewsDetailPage />
        </Route>

        <Route path="/novice-guide/">
          <NoviceGuidePage />
        </Route>

        <Route path="/about/officers/">
          <OfficersPage />
        </Route>
    </Router>

  document.title = "Triad Sports Car Club";
  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <NavBar />
        <div className="main-wrapper">
          <div className="main">
            {mainPanelRouter}
          </div>       
        </div>
        <div className="vertical-filler" />
        <Footer />  
      </QueryClientProvider>
    </div>
  );
}

export default App;
