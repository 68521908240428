//import React, { useState, useEffect } from 'react';
import FeatureBox from '../shared/FeatureBox';
//import { useParams } from "react-router-dom";
//import globals from "../globals.json";
import { format, parseISO } from 'date-fns';
import LoadingWidget from '../shared/LoadingWidget';
import ErrorPanel from '../shared/ErrorPanel';


//props should be an object as returned by React Query / event
function EventNextCard(props) {
    let content = '';
    let labelContent = 'Next Event';
    let footerContent = '';
    let headerContent = '';
    let bodyContent = '';
    let footerLinks = [];
    if(props.event.isLoading)
    {
        bodyContent = <LoadingWidget />;
    }
    else if(props.event.isError)
    {
        bodyContent = <ErrorPanel />;
    }
    else if(props.event.data == null)
    {
        bodyContent = "No upcoming events; check back soon!"; 
    }
    else
    {
        let date = "";
            
        if(props.event.data != null)
        {
            date = format(new Date(props.event.data.start_date),'EEE, MMM d');
            if(props.event.data.end_date !== null)
            {
                date = `${date} - ${format(new Date(props.event.data.end_date),'EEE, MMM d')}`;
            }
            let link = `/event/${props.event.data.id}/${props.event.data.slug}`;

            bodyContent = props.event.data.title;
            headerContent = date;

            if(props.event.data.registration_url !== null)
            {
                footerLinks.push(<a className="link-btn-tertiary" key="registerLink" href={props.event.data.registration_url} target="_blank">Register</a>);
            }
            footerLinks.push(<a className="link-btn-hollow" key="detailsLink" href={link}>Details</a>);
        }
        else{
            bodyContent = "No upcoming events; check back soon!";
        }                 
    }
    return (
        <FeatureBox headerContent={headerContent} bodyContent={bodyContent} labelContent={labelContent} footerContent={footerLinks} />
	);
}
export default EventNextCard;