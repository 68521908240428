import globals from "../../globals.json";
import { useQuery } from 'react-query';
import LoadingWidget from "../shared/LoadingWidget";
import ErrorPanel from "../shared/ErrorPanel";
import parse from "html-react-parser";
import WideTile from "../shared/WideTile";
import PageContent from "../shared/PageContent";


export default function GettingStartedPage(props)
{

    const content = useQuery(['pages','about'],() =>
		fetch(`${globals.api_base_url}/pages/about`).then(res=> res.json())
	);

    let panel1Title = "Become a Member";
    let panel1Body = "Membership benefits include discounted registration fees and eligibility for year-end awards.";
    let panel1Image = globals.media_url + "2023/01/peeps.jpg";
    let panel1MoreLink = "/about/membership/";
    let panel1 = 
        <WideTile body={panel1Body} title={panel1Title} imageUrl={panel1Image} imagePosition="left" readMoreUrl={panel1MoreLink} readMoreLabel="Read More"/>

    let panel2Title = "Meet the Staff"
    let panel2Body = "Triad Sports Car Club is a volunteer-run, grassroots organization. Get to know the folks who are bringing Autocross to a site near you.";
    let panel2MoreLink = "/about/officers/";
    let panel2MoreLabel = "Read More";
    let panel2Image = globals.media_url + "2023/01/karts.jpg";
    let panel2 = <WideTile body={panel2Body} title={panel2Title} imageUrl={panel2Image} imagePosition="right" readMoreUrl={panel2MoreLink} readMoreLabel={panel2MoreLabel}/>

    let panel3Title = "Club Rules";
    let panel3Body = "From decibel limits to tech inspections, if you're looking for more in-depth procedures, you can find them here."
    let panel3MoreLink="/about/rules";
    let panel3MoreLabel = "Read More";
    let panel3Image = globals.media_url + "2023/01/86p.jpg";
    let panel3 = <WideTile body={panel3Body} title={panel3Title} imageUrl = {panel3Image} imagePosition = "right" readMoreUrl={panel3MoreLink} readMoreLabel={panel3MoreLabel} />

    //panel2 is not currently being displayed -- work in progress.

    return(
        <>
            <h1>About Triad Sports Car Club</h1>
            <PageContent content={content} />
            {panel1}
            {panel3}
        </>
    )
}