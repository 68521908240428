import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faDiscord } from "@fortawesome/free-brands-svg-icons"
import './Footer.css';
import globals from "../../globals.json";
//import logo from '../../triad_logo_300h.png';
import logo from '../../img/tscc_1c_yellow.png';

export default function Footer(props)
{
    let content =
        <div className="footer">
            <div className="footer-inner">
            <div className="footer-image">
                <a href="/"><img src={logo}/></a>
            </div>
            <div className="footer-links-wrapper">
                <div className="footer-links">
                    <ul>
                        <li><a href="/events">Events</a></li>
                        <li><a href="/news">News</a></li>
                        <li><a href="/getting-started/">Getting Started</a></li>
                        <li><a href="/about">About</a></li>                    
                    </ul>
                </div>
                <div className="footer-links">
                    <ul>
                        <li><a href="/contact">Contact Us</a></li>                    
                        <li><a href="https://discord.gg/NtWpwyzuAB" target="_blank"><FontAwesomeIcon icon={faDiscord} /> Discord Server</a></li>
                        <li><a href="https://www.facebook.com/groups/2252377269" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Facebook Group</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-copyright">
                © {new Date().getFullYear()} Triad Sports Car Club
            </div>
            </div>
        </div>
        return (content) ;
}