//import React, { useState, useEffect } from 'react';
import {Col,Row} from 'react-bootstrap';
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import globals from "../../globals.json";
import { useQuery } from 'react-query';
import { format, parseISO } from 'date-fns';

import ErrorPanel from '../shared/ErrorPanel';
import LoadingWidget from '../shared/LoadingWidget';

import ReactMarkdown from 'react-markdown'


//import ReactMarkdown from "react-markdown";

export default function EventDetailLocationPanel(props) {
	
    let location = props.location;


    let innerContent = null;

    if(location !== null)
    {
        return(
        <div className="section">
            <h2>Event Location</h2>
            <p>
                <b>{location.name}</b><br/>
                <a href={`https://www.google.com/maps/place/${location.address}`} target="_new">{location.address}</a>
            </p>
            <div><ReactMarkdown>{location.directions}</ReactMarkdown></div>
        </div>);
                
    }
    else{
    	return (       
            <div className="section">
                <h2>Event Location</h2>
                <p>We're still finalizing the location of this event.</p>
            </div>
	    );
    }
}