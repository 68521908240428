import { useParams } from "react-router-dom";
import globals from "../../globals.json";
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import { format,parseISO } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SeasonSelect from './SeasonSelect';
import LoadingWidget from "../shared/LoadingWidget";
import ErrorPanel from "../shared/ErrorPanel";
import EventList from "./EventList";
import './Events.css';

function SeasonList(props)
{
    const { year } = useParams();
    let myYear = parseInt(year);

    if(isNaN(myYear))
    {
        myYear = new Date().getFullYear();
    } 
    const events = useQuery(['seasons',myYear],() =>
        fetch(`${globals.api_base_url}/events/season/${myYear}`).then(res => res.json())
    );

    let content = null;
    
    content =
        <div className="season-list">
            <h1 className="section-heading">{myYear} Events</h1>
            <div className="seasonList-nav">
                <div className="seasonList-nav-left"><a href={`/season/${myYear-1}`}> <FontAwesomeIcon icon="fa-angles-left" /> {myYear-1}</a></div>
                <div className="seasonList-nav-filler"><SeasonSelect /></div> 
                <div className="seasonList-nav-right" ><a href={`/season/${myYear+1}`}> {myYear+1} <FontAwesomeIcon icon="fa-angles-right" /></a></div>
            </div>
            <EventList events={events} />
        </div>;

	return(			
        content
	);
}
export default SeasonList;