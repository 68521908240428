//import React, { useState, useEffect } from 'react';
import parse from "html-react-parser";
//import { useParams } from "react-router-dom";
//import globals from "../globals.json";
import { format, parseISO } from 'date-fns';
import LoadingWidget from '../shared/LoadingWidget';
import ErrorPanel from '../shared/ErrorPanel';
import FeatureBox from '../shared/FeatureBox';
import EventLocationWidget from "../events/EventLocationWidget";

//props should be an object as returned by React Query / event
function MeetingNextCard(props) {
    let content = '';
    let bodyContent = '';
    let headerContent = '';
    let labelContent = 'Next Meeting';
    let footerContent = '';    

    if(props.meeting === undefined)
    {
        console.log("props.meeting is undefined")
    }
    console.log(props.meeting);
    if(props.meeting.isLoading)
    {
        bodyContent = <LoadingWidget />;
    }
    else if(props.meeting.isError)
    {
        bodyContent = <ErrorPanel />;
    }
    else if(props.meeting.data == null)
    {
        bodyContent = "No upcoming events; check back soon!"; 
    }
    else
    {
        if(props.meeting.data !== undefined)
        {            
            var date = format(new Date(props.meeting.data.date),'EEE, MMM d');
            date += " @ " + props.meeting.data.time;
            headerContent = date;

            let location = 'Location TBA';
            if(props.meeting.location_name !== null)
            {
                let locData = {
                    address:props.meeting.data.location_address,
                    name: props.meeting.data.location_name,
                    city_state: props.meeting.data.location_address
                }
                let key = `loc-${props.meeting.id}`;
                location = 
                    <><br/><EventLocationWidget key={`loc-${props.meeting.data.id}`} location={locData} /></>;
            }
            bodyContent = location;
        }
        else{
            bodyContent = "No upcoming meetings; check back soon!";
        } 
    } 
    return (
        <FeatureBox headerContent={headerContent} bodyContent={bodyContent} labelContent={labelContent} footerContent={footerContent} />
	);
}
export default MeetingNextCard;