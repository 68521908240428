import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './NavBar.css';
import globals from "../../globals.json";
import logo from '../../img/tscc_1c_yellow.png';

export default function NavBar(props)
{
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    let content = 
    <div className="navbar-wrapper">
        <div className="navbar">
            <div className="navbar-logo-div">
                <a href="/"><img height="60px" src={logo}/></a>
            </div>
            <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                <ul>
                    <li><a className="navbar-link" href="/events/">Events</a></li>
                    <li><a className="navbar-link" href="/news/">News</a></li>
                    <li><a className="navbar-link" href="/getting-started/">Getting Started</a></li>
                    <li><a className="navbar-link" href="/about/">About</a></li>
                    <li><a className="navbar-link" href="/contact/">Contact</a></li>
                </ul>
            </div>
            <div className="navigation-permanent">
                <ul><li><a className="navigation-live-timing" href={globals.live_timing_url}>Live Timing</a></li></ul>
            </div>
            <button className="navigation-hamburger" 
                onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                }}
            >
                <FontAwesomeIcon icon="fa-bars" />
            </button>
        </div>
    </div>;
    return(
        content
    );
}