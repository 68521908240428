import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from "react-router-dom";
import globals from "../globals.json";
import { useQuery } from 'react-query';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { format,parseISO } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OfficersList from './OfficersList';

function OfficersPage(props)
{
    //fetch officers here
    const officers = useQuery(['officers','officers'],() =>
        fetch(`${globals.api_base_url}/officers/officers/`).then(res => res.json())
    );
    const assistants = useQuery(['officers','assistants'],() =>
        fetch(`${globals.api_base_url}/officers/assistants/`).then(res => res.json())
    );
  
    return(			
        <Container>            
            <Row><Col><h1>Club Volunteers</h1></Col></Row>
                <OfficersList officers={officers} title='Officers' />
                <OfficersList officers={assistants} title='Assistant Staff'/>
        </Container>
    );
}
export default OfficersPage;