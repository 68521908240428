import {React} from 'react';
import { Link } from "react-router-dom";
import { format } from 'date-fns';

import './News.css';
import globals from "../../globals.json";
import { useQuery } from 'react-query';
import {fetch_app_settings} from '../../Data.js';


//for archive views
//props should include a news post, not a query object
//TODO: alt text on images?
export default function NewsPreview(props) 
{
    let post = props.post;
    let date = format(new Date(post.date),'MMMM d, yyyy');
    let imageCol = '';
    let postUrl = `/news/${post.slug}`;
    if(post.featured_image !== undefined)
    {
        let myStyle = {
            backgroundImage: 'url(' + post.featured_image + ')',
        };
        imageCol =
            <div className="newsListItem-bgImage" style={myStyle} /> 
    }
    let jumpLink = '';
    if(post.has_more_content)
    {
        jumpLink = <Link to={postUrl}>Read more &gt;&gt;</Link>
    }
    let content = 
        <div className="newsListItem">
            {imageCol}
            <div className="newsListItem-text">
                <div className="newsListItem-title"><a href={postUrl}>{post.title}</a></div>
                <div className="newsListItem-dateline">{date}</div>
                <div>{post.content}</div>
                <div className="newsListItem-jumplink">{jumpLink}</div>
            </div>
            
        </div>;
    return content;
}
