import { useParams } from "react-router-dom";
import globals from "../../globals.json";
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import { format,parseISO } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SeasonSelect from './SeasonSelect';
import LoadingWidget from "../shared/LoadingWidget";
import ErrorPanel from "../shared/ErrorPanel";
import EventListItem from "./EventListItem";
import './EventListItem.css';

function EventList(props)
{
    let events = props.events;
    let content = null;

    if(events.isLoading)
    {   
        content = <LoadingWidget />;
    }
    else if(events.isError)
    {
        content = <ErrorPanel />;
    }
    else{    
        //events.data.forEach(element => console.log(element.id));

        let eventsList = 
            events.data.map((lineItem) => 
        <EventListItem key={lineItem.id} event={lineItem} />)
        content = <div>
            {eventsList}
        </div>;
    }

	return(			
        content 
    );
}
export default EventList;