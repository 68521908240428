//import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import parse from "html-react-parser";
import FeatureBox from '../shared/FeatureBox';
import globals from "../../globals.json";
//import { useParams } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import LoadingWidget from '../shared/LoadingWidget';
import ErrorPanel from '../shared/ErrorPanel';


//props should be an object as returned by React Query / event
function LiveTimingCard(props) {
    let content = '';
    let bodyContent = 'Click to view live timing from our most recent event';
    let footerContent = <a className="link-btn-tertiary" href={globals.live_timing_url}>Live Timing</a>;
        content = 
        <FeatureBox labelContent='Live Timing' headerContent='Live Timing' bodyContent={bodyContent} footerContent={footerContent} />           
    
    return (
            content
	);
}
export default LiveTimingCard;