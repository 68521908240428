import React from 'react';
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import globals from "../../globals.json";
import { useQuery } from 'react-query';
import {fetch_app_settings} from '../../Data.js';
import PageContent from '../shared/PageContent.js';
import NewsList from '../news/NewsList.js';
import logo from '../../img/tscc_muted.png';

import EventNextCard from './EventNextCard';
import MeetingNextCard from './MeetingNextCard';
import LiveTimingCard from './LiveTimingCard';

import './HomePage.css';
import LoadingWidget from '../shared/LoadingWidget';
import LatestNewsWidget from '../news/LatestNewsWidget';

export default function HomePage(props)
{
	const settings = useQuery(['settings'],
		fetch_app_settings,
		globals.max_cache_config
	);

	const content = useQuery(['pages','home'],() =>
		fetch(`${globals.api_base_url}/pages/home`).then(res=> res.json())
	);

	const nextEvent = useQuery(['event','next'], () =>
		fetch(`${globals.api_base_url}/events/next`).then(res=>
			{						
				if(res.ok){				
					return res.json();
				}	
				else{
					return null;
				}
			}
		));
	const nextMeeting = useQuery(['meeting','next'],() =>
		fetch(`${globals.api_base_url}/meetings/next`).then(res =>
			{					
				if(res.ok){				
					return res.json();
				}	
				else{
					return null;
				}	
			}
	));

	let main = 
		<>
			<div className="header section"><img className="header-image" src={logo}/></div>
			<div className="section featureBox-row">
				<EventNextCard event={nextEvent}/> 
				<MeetingNextCard meeting={nextMeeting} />
				<LiveTimingCard />
			</div>
			<div className="section">
				{<PageContent content={content} />}
			</div>
			<LatestNewsWidget title="Latest News" />
		</>;
	return(
		main
	);
}
