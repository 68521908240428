
//expects:
//props.address: an address that google maps will be able to find.

import { Children } from "react";

//props.display: what shows in the a on the screen.
export default function MapLink(props)
{
    return(
        <a href={`https://www.google.com/maps/place/${props.address}`} className={props.className} target="_blank">{props.children}</a>
    )
}